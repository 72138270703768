<div class="container">
  <div class="mt-5 row justify-content-center">
    <div class="col-md-8">
      <mat-card appearance="outlined">
        <mat-card-title>
          <div class="d-flex">
            <mat-icon color="primary" class="align-self-center mr-1">gpp_maybe</mat-icon>
            Unauthorized
          </div>
        </mat-card-title>
        <mat-card-content>
          <p>You need to be logged in as an authorized user to view the requested page.</p>
          <p>Please contact an administrator for assistance.</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
